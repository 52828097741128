
    .student-wrapper {
        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 60px;
            background: #fff;
            position: relative;

            .head-btn {
                height: 100%;
                margin-left: 24px;
                display: flex;
                align-items: center;
            }

            .header-left {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 24px;
                color: #FD4446;
                font-weight: 500;
                font-size: 18px;
            }

            .train-manage {
                border: 1px solid #FD4446;
                padding: 10px 11px;
                color: #FD4446;

                &:hover {
                    color: #fd4446d9;
                    background-color: #fff;
                    border: 1px solid #fd4446d9;
                }

                &.blue {
                    border: unset;
                    color: #fff;
                    background: #1122D8;
                    border-radius: 4px;
                }
            }

            .header-right {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-right: 30px;

                .user-info {
                    display: flex;
                    align-items: center;

                    .user-avatar {
                        width: 32px;
                        height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        border-radius: 50%;
                        border: 1px solid #eee;
                        box-sizing: border-box;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    .user-name {
                        margin-left: 6px;
                    }
                }

                .exit-btn {
                    margin-left: 24px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    transition: all .3s;
                    height: 24px;

                    .iconfont {
                        font-size: 24px;
                        color: #1122D8;
                    }

                    div {
                        height: 24px;
                        line-height: 30px;
                    }

                    &:hover {
                        color: #FD4446;
                    }
                }
            }
        }

        .train-content {
            height: calc(100vh - 60px);
            width: 100%;
            background: url("../../assets/images/studentIndex/index-bg.png") no-repeat bottom center, linear-gradient(#04BEFE, #4482EB);
            background-size: cover;
            display: flex;
            justify-content: center;

            .inner-content {
                padding: 76px 175px;
                height: calc(100% - 252px);
                width: 100%;

                .inner-head {
                    text-align: center;
                    font-weight: 500;
                    font-size: 30px;
                    color: #FFFFFF;
                }

                ::v-deep .el-scrollbar {
                    height: 100%;
                    margin-top: 100px;

                    .el-scrollbar__wrap {
                        overflow-x: hidden;

                        .el-scrollbar__view {
                            height: 100%;
                            width: calc(100% - 10px);
                            margin-left: 10px;
                            display: flex;
                            flex-wrap: wrap;
                        }
                    }
                }

                .train-item {
                    width: calc(20% - 70px);
                    height: calc(50% - 50px);
                    background: #FFFFFF;
                    box-shadow: 0px 0px 9px 0px rgba(51, 51, 51, 0.08);
                    border-radius: 8px;
                    margin: 10px 70px 50px 0;
                    min-height: 300px;
                    position: relative;

                    &:nth-of-type(5n) {
                        margin-right: 0;
                    }

                    .shadow-box {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        background: #fff;
                        box-shadow: 0px 0px 9px 0px rgba(51, 51, 51, 0.08);
                        border-radius: 8px;
                    }

                    &:hover {
                        cursor: pointer;

                        .shadow-box {
                            top: -10px;
                            left: -10px;
                            height: calc(100% + 20px);
                            width: calc(100% + 20px);
                        }
                    }

                    .img-box {
                        margin-top: 36px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .train-num {
                        text-align: center;
                        font-family: BigYoungBold;

                        .current-train {
                            font-size: 48px;
                            color: #1122D8;
                        }

                        .train-each {
                            font-size: 18px;
                            color: #000000;
                        }
                    }

                    .enter-text {
                        text-align: center;
                        font-size: 18px;
                        color: #000000;
                        font-weight: 500;
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    ::v-deep .end-dialog {
        .el-dialog__header {
            border-bottom: 1px solid #eee;
        }

        .el-dialog__headerbtn:focus .el-dialog__close,
        .el-dialog__headerbtn:hover .el-dialog__close {
            color: #FD4446;
        }

        .end-competition {
            text-align: center;

            img {
                height: 170px;
            }

            .text {
                margin-top: 28px;
                color: #666;
                font-size: 18px;
            }
        }
    }

    .train-dialog-content {
        display: flex;
        flex-direction: column;
        line-height: 2;
    }
